.root {
  width: 553px;
  height: 527px;
  max-height: 800px;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 1 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.link, .taskStatus {
  cursor: pointer;
  color: blue;
  text-decoration: none;
}