.mainView {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
}

.titleBlock {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.link, .taskStatus {
  cursor: pointer;
  color: blue;
  text-decoration: none;
}

.head {
  position: relative;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  color: rgba(0, 10, 25, 0.8);
  margin-left: 16px;
  margin-right: 16px;
}

.modal {
  position: relative;
  max-width: 600px;
  min-height: 200px;
  background: #fff;
  box-shadow: 0 4px 40px rgba(13, 28, 51, 0.16);
  outline: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.content {
  flex: 1 1 0;
  overflow-y: auto;
  padding-top: 25px;
  padding-left: 16px;
  padding-right: 16px;
  width: 80%;
  font-size: 16px;
  text-align: justify;
}

.footer {
  position: absolute;
  bottom: 16px;
  right: 16px;
  display: flex;
  grid-gap: 16px;

}

.btn {
  width: 160px;
}