.subtitle {
  margin-bottom: 5px;
  color: rgba(0, 10, 25, 0.8);
}

.error {
  margin-top: 4px;
  color: #dc664d;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rightLabel {
  margin-left: 11px;
  color: #000a19cc;
}
