@import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
@import 'mapbox-gl/dist/mapbox-gl.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', source-code-pro, Menlo, sans-serif;
  background: #f5f7f9;
}

code {
  font-family: 'Roboto', source-code-pro, Menlo, sans-serif;
}

.mapboxgl-canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  cursor: default;
}

.text-16-500 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.text-16-400 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.text-16-300 {
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
}

.text-12-400 {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(27, 43, 72, 0.6);
}

.text-14-400 {
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: #000a19cc;
}

.text-light-14-400 {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #1b2b4899;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.mapboxgl-ctrl-group button {
  background: #fff;
  border: 1px solid #dadde5;
  border-radius: 2px !important;
  box-shadow: none;
}

.mapboxgl-ctrl-group:not(:empty) {
  box-shadow: none;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {
  background-image: url('images/icons/fullscreen.svg');
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
  background-image: url('images/icons/geolocate.svg');
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url('images/icons/add.svg');
}
.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url('images/icons/remove.svg');
}
.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out {
  margin-top: -1px;
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
  margin-right: 54px;
}
.mapboxgl-ctrl-group:last-child {
  margin-bottom: 88px;
}
