.header {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  max-width: 1440px;
  margin: 0 30px;
  box-sizing: border-box;
}

.logo {
  text-decoration: none;
  cursor: pointer;
}

.middle {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 18px;
}

.middleLinksBlock {
  height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
  max-width: 500px;
  margin-left: 200px;
  align-items: center;
}

.user {
  flex-grow: 0;
}

.link {
  color: #6d7484;
  margin: 0 5px;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 70px;
  justify-content: center;
  text-align: center;
  text-decoration: none;
}

.noCursor {
  cursor: default;
}

.active {
  position: relative;
  height: 100%;
  color: #000a19;
  display: flex;
}

.active::after {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #2e70ef;
}
