.main {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1 1 auto;
  min-height: 0;
  min-width: 650px;
}

.leftBlock {
  margin-right: 27px;
  flex: 1 1 533px;
  display: flex;
  flex-direction: column;
}

.map {
  flex: 1 1 800px;
  border-radius: 2px;
  overflow: hidden;
}

.box {
  padding: 0px;
  display: flex;
  min-height: 0;
}

.titleId {
  margin-left: 15px;
  margin-top: 10px
}

.titleBlock {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
}