.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.fld {
  width: 350px;
  margin-bottom: 10px;
}

.fld fieldset {
  border-radius: 0px;
}

.signinButton {
  width: 100%;
  max-width: 350px;
}

.forgotPasswordLink {
  margin-top: 20px;
  font-size: 14px;
}

.href {
  margin-left: 4px;
}

.restoreText {
  font-size: 20px;
  margin-bottom: 62px;
  max-width: 593px;
  text-align: center;
}
