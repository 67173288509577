/* .wrapper {
  position: relative;
}
.wrapperDisabled {
  opacity: 0.7;
  pointer-events: none;
} */
.placeholder {
  align-items: center;
  display: flex;
  width: 90px;
  height: 40px;
  border-radius: 1px;
  cursor: pointer;
}
/* .placeholder:hover {
  border: 1px solid #212121;
}
.placeholderDisabled {
  opacity: 0.5;
  cursor: default;
} */

/* .placeholderDisabled:hover {
  opacity: 0.5;
  cursor: default;
  background: #fff;
} */

.placeholderText {
  /* flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  /* padding-right: 10px; */
  font-weight: 400;
  font-size: 14px;
  color: #22366d;
  text-align: start;
  padding-left: 14px;
}
.placeholderIcon {
  transition: 0.2s ease;
  /* margin: 10px; */
  padding-left: 5px;
}
.placeholderIconOpen {
  transform: rotate(180deg);
}
.dropdownItem {
  font-weight: 400;
  font-size: 14px;
  /* line-height: 16px; */
  color: #465384;
  padding: 11px 15px;
  cursor: pointer;
  transition: 0.2s ease;
}
/* .dropdownItem:hover {
  background: #f5f7f9;
  color: #22366d;
} */
/* .subtitle {
  margin-bottom: 5px;
  color: rgba(0, 10, 25, 0.8);
} */
