.additionalInfoHeader {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  margin: 10px 0px;
  color: #000a19cc;
}

.icon {
  margin-left: 15px;
}

.icon svg path {
  fill: #323232;
}

.showMore {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.row {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0 33px;
  height: 35px;
}

.textfieldColor {
  max-width: 120px;
  margin-right: 15px;
}

.textfieldNumber {
  max-width: 100px;
  margin-right: 15px;
}

.visualSettingsRow {
  display: flex;
  margin-bottom: 15px;
}
