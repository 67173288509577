.root {
  width: 100%;
  padding-top: 26px;
  padding-bottom: 31px;
  box-shadow: 0px -1px 0px 0px rgb(218 221 229) inset;
}

.additionalInfoHeader {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 33px;
  padding-right: 30px;
}

.details {
  cursor: pointer;
  font-weight: 500;
  flex-wrap: nowrap;
}

.icon {
  margin-left: 14px;
}

.semibold {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.textInfo {
  margin: 0px 9px;
}

.twoColumns {
  display: flex;
  padding-left: 33px;
  padding-right: 30px;
  font-size: 14px;
  padding-top: 19px;
  justify-content: space-between;
}

.subtitle {
  font-weight: 700;
  margin-bottom: 14px;
}

.column {
  margin-right: 23px;
  line-height: 22px;
}
