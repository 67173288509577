.transferRouteItem {
  align-items: center;
  display: grid;
  box-shadow: 0px -1px 0px 0px rgba(218, 221, 229, 1) inset;
  height: 35px;
  grid-template-columns: 1fr 1fr 2fr 2.2fr 5fr 1fr;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  cursor: pointer;
}

.transferRouteItemShort {
    align-items: center;
    display: grid;
    box-shadow: 0px -1px 0px 0px rgba(218, 221, 229, 1) inset;
    height: 35px;
    grid-template-columns: 1fr 1.5fr 3fr 7fr 1fr;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    cursor: pointer;
  }

.transferRouteItem:hover {
  background-color: #f5f4f4;
}

.highlighted {
  background-color: #d9d9ed;
}

.highlighted:hover {
  background-color: #c9c9dc;
}

.text {
  padding-left: 5px;
}

.icon {
  text-align: center;
  position: relative;
}

.iconStart {
  position: absolute;
  top: -7px;
  transform: translate(-50%, 0%);
}

.iconFinish {
  position: absolute;
  transform: translate(-50%, 0%);
  bottom: -7px;
}

.zoneItemColSmall {
  text-align: center;
  z-index: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.menuItemIcon {
  margin: 0 12px;
  width: 13px;
}

.smallBtn {
  height: 24px;
  max-width: 60px;
  font-size: 14px;
  margin-right: 11px;
}

.buttonsBlock {
  display: flex;
  justify-content: flex-start;
}

.bold {
  font-weight: 700;
}

.length {
  font-size: 12px;
  margin-left: 36px;
}
