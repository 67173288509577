.root {
  width: 390px;
  height: 156px;
  position: absolute;
  background: #fff;
  box-shadow: 0 4px 40px rgba(13, 28, 51, 0.16);
  border-radius: 2px;
  outline: none;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.desc {
  color: rgba(27, 43, 72, 0.6);
}

.loader {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  margin-right: 25px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
