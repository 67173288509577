.wrapper {
  position: relative;
}
.wrapperDisabled {
  opacity: 0.7;
  pointer-events: none;
}
.placeholder {
  align-items: center;
  display: flex;
  width: 100%;
  height: 40px;
  border: 1px solid #dadde5;
  border-radius: 0px;
  cursor: pointer;
}
.placeholder:hover {
  border: 1px solid #212121;
}
.placeholderDisabled {
  opacity: 0.5;
  cursor: default;
}

.placeholderDisabled:hover {
  opacity: 0.5;
  cursor: default;
  background: #fff;
}

.placeholderText {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
  font-weight: 400;
  font-size: 14px;
  color: #2c3e51;
  text-align: start;
  padding-left: 14px;
}
.placeholderIcon {
  transition: 0.2s ease;
  margin: 10px;
}
.placeholderIconOpen {
  transform: rotate(180deg);
}
.dropdownItem {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #97a1bc;
  padding: 11px 15px;
  cursor: pointer;
  transition: 0.2s ease;
}
.dropdownItem:hover {
  background: #f5f7f9;
  color: #22366d;
}
.subtitle {
  margin-bottom: 5px;
  color: rgba(0, 10, 25, 0.8);
}
