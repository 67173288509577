.root {
  display: flex;
  margin-bottom: 5px;
  margin-top: 37px;
}

.button {
  width: 205px;
  margin-right: 16px;
}

.buttonStop {
  width: 136px;
}

.row {
  display: flex;
}

.buttonSmall {
  height: 30px;
  margin-right: 5px;
  font-size: 14px;
  width: 170px;
}
