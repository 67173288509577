.missions {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
}

.titleBlock {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
