.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  box-sizing: border-box;
}

.main {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1 1 auto;
  min-height: 0;
  min-width: 800px;
}

.leftBlock {
  margin-right: 27px;
  flex: 1 1 553px;
  display: flex;
  flex-direction: column;
  max-width: 553px;
}

.map {
  flex: 1 1 800px;
  border-radius: 2px;
  overflow: hidden;
}

.box {
  padding: 0px;
  display: flex;
  min-height: 0;
  flex-direction: column;
}
