.avatar {
  width: 40px;
  height: 40px;
  background: #dadde5;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatarText {
  color: #63729a;
  font-weight: 500;
}

.cutText {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 148px;
  white-space: nowrap;
  margin-left: 12px;
  font-weight: 500;
  color: #22366d;
}

.userMenuBlock {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-shadow: inset -1px 0px 0px #dadde5, inset 1px 0px 0px #dadde5;
  padding-left: 24px;
  padding-right: 24px;
  height: 75px;
  max-width: 266px;
}
