.tableWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tableHeader {
  grid-template-columns: 1fr 2fr 3fr 3fr 2fr 2fr 1fr;
  display: grid;
  height: 45px;
  box-shadow: 0px -1px 0px 0px rgba(218, 221, 229, 1) inset;
}

.tableHeaderItem {
  align-items: center;
  display: flex;
  padding-left: 5px;
}

.tableHeaderItemSmall {
  align-items: center;
  display: flex;
  justify-content: center;
}

.emptyWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: rgba(99, 114, 154, 1);
}

.emptyTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 16px;
}

.emptyDescription {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: center;
  max-width: 330px;
}

.box {
  padding: 0px;
  display: flex;
  min-height: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
