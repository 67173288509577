.root {
  position: absolute;
  z-index: 100;
  bottom: 190px;
  right: 54px;
}

.button {
  width: 29px;
  height: 29px;
  display: block;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;

  background: #fff;
  border: 1px solid #dadde5;
  border-radius: 2px !important;
  box-shadow: none;
}

.button:not(:disabled):hover {
  background-color: #f2f2f2;
}

.button:disabled {
  background-color: #dcdcdc;
}

.buttonActive {
  border: solid 1px #000;
}

.icon {
  background-image: url('images/icons/ruler.svg');
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
}
