.root {
  position: relative;
  max-width: 600px;
  height: 700px;
  background: #fff;
  box-shadow: 0 4px 40px rgba(13, 28, 51, 0.16);
  outline: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.head {
  position: relative;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  color: rgba(0, 10, 25, 0.8);
  margin-left: 48px;
  margin-right: 48px;
}

.textfield {
  margin-bottom: 35px;
}

.contentColumns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
}

.selectBlock {
  display: flex;
  align-items: flex-end;
  padding-top: 25px;
}

.footer {
  display: flex;
  grid-gap: 16px;
  justify-content: flex-end;
  margin: 0px 48px;
}

.btn {
  max-width: 160px;
}

.btnSelect {
  height: 40px;
  max-width: 152px;
  font-size: 14px;
  margin-left: 11px;
}
.btnClear {
  height: 40px;
  max-width: 152px;
  font-size: 14px;
  margin-left: 11px;
  /* white-space: nowrap; */
}

.dateTimeContainer { 
  display: flex;
  align-items: center; 
  /* gap: 16px; */
  width: 100%;
  padding-bottom: 5px;
  justify-content: space-between 
}

.singleSettingsDateTimeContainer { 
  display: flex;
  align-items: center; 
  /* gap: 16px; */
  width: 100%;
  padding-top: 40px; 
  justify-content: space-between 
}

.textfieldFullWidth {
  width: 100%;
}

.error {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  color: #dc664d;
  margin-top: 4px;
}

.modal {
  padding: 21px 0px 48px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}

.content {
  flex: 1 1 0;
  overflow-y: auto;
  padding-top: 25px;
  padding-left: 48px;
  padding-right: 48px;
  width: 80%;
}

.rightMargin {
  margin-right: 79px;
}

.title {
  margin-bottom: 20px;
}

.weekdays {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.fieldContainer { 
  display: flex;
  align-items: center; 
  gap: 16px;
  width: 100%;
  padding-bottom: 25px;
  padding-top: 25px;
}