.tableHeader {
  display: grid;
  grid-template-columns: 1.5fr 2.5fr 1fr 1.8fr 0.7fr;
  box-shadow: 0px -1px 0px 0px rgba(218, 221, 229, 1) inset;
  height: 70px;
  align-items: center;
  padding: 0 20px;
}

.tableItem {
  display: grid;
  grid-template-columns: 1.5fr 2.5fr 1fr 1.8fr 0.7fr;
  box-shadow: 0px -1px 0px 0px rgba(218, 221, 229, 1) inset;
  height: 70px;
  align-items: center;
  cursor: pointer;
  padding: 0 20px;
}

.tableItem:hover {
  background-color: #f4f4f4;
}

.table {
  flex: 1 1 0;
  overflow: auto;
}

.selected {
  background-color: #f5f4f4;
}

.link {
  cursor: pointer;
  color: blue;
  text-decoration: none;
}

.missionName {
  display: flex;
  justify-content: space-between;
}

.centered {
  text-align: center;
}
