.main {
  height: 75px;
  box-shadow: 0px -1px 0px 0px rgba(218, 221, 229, 1) inset;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 34px;
}

.idle {
  background-color: #eee59733;
}

.work {
  background-color: #eee59733;
}

.error {
  background-color: #dc664d33;
}

.success {
  background-color: #3ab08433;
}

.button {
  height: 28px;
  max-width: 130px;
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
}

.buttonWide {
  height: 45px;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 14px;
  width: 300px;
}

.message {
  flex: 1 1 0;
  margin-left: 29px;
  font-weight: 400;
  font-size: 14px;
}

.text {
  flex: 1 1 0;
  /* margin-left: 10px; */
  font-weight: 400;
  font-size: 14px;
}

.retryText {
  font-size: 14px;
  margin-right: 10px;
  color: #1b2b4899;
}

.link {
  cursor: pointer;
  color: blue;
  text-decoration: none;
}