.button {
  background-color: #ffffff;
  height: 75px;
  border: 0px;
  margin-right: 10px;
  align-items: center;
  display: flex;
  cursor: pointer;
  padding: 0 16px;
}

.button:hover {
  background-color: #fbfbfb;
  border: 0px;
}

.button:last-child {
  margin-right: 0;
}

.button:disabled {
  cursor: default;
  opacity: 1;
}

.buttonOutlined {
  border: 1px solid #4e78f6;
}

.buttonWide {
  flex: 1 1 219px;
  width: 100%;
  color: #000a19cc;
}

.buttonWide:disabled {
  border: 0px;
  background-color: #f8f8f8;
  color: #7c889ccc;
}

.buttonNarrow {
  max-width: 94px;
}

.buttons {
  width: 100%;
  margin-bottom: 21px;
  display: flex;
}

.active {
  box-shadow: inset 0px 0px 0px 1px #4e78f6;
}

.active:hover {
  box-shadow: inset 0px 0px 0px 1px #4e78f6;
}

.complexButtonContent {
  display: flex;
  align-items: center;
  width: 100%;
}

.complexButtonDesc {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 15px;
}

.complexButtonTextTitle {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.complexButtonTextDesc {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}

.dropdown {
  flex-direction: column;
  position: absolute;
  width: 100%;
  right: 0px;
  top: 0px;
  z-index: 6;
  box-shadow: 0 2px 6px rgb(128 145 192 / 40%);
}

.positionRelative {
  position: relative;
}

.bottomShadow {
  box-shadow: 0px -1px 0px 0px rgba(218, 221, 229, 1) inset;
}

.dropdownArrow {
  position: absolute;
  right: 10px;
}

.icon {
  width: 34px;
}
