.btnShowActions {
  height: 24px;
  width: 24px;
  background: transparent;
  border: 0px;
  padding: 0 0;
  min-height: 12px;
  margin-left: 7px;
  margin-right: 5px;
}

.btnShowActions:hover {
  background: transparent;
  border: 0px;
}

.btnShowActions:active {
  background: transparent;
  border: 0px;
}

.btnShowActions svg path {
  fill: #97a1bc;
}

.btnShowActions:hover svg path {
  fill: #22366d;
}

.btnShowActions:active svg path {
  opacity: 0.8;
}

.menu {
  box-shadow: 0px 6px 10px rgba(37, 44, 61, 0.25);
  border-radius: 0px;
  min-width: 160px;
  font-size: 14px;
  color: #97a1bc;
}

.menuList {
  padding-top: 4px;
}

.menuItem span {
  color: #97a1bc;
  font-size: 14px;
}

.menuItem:hover span {
  color: #22366d;
}

.menuItem svg path {
  fill: #97a1bc;
}

.menuItem:hover svg path {
  fill: #22366d;
}

.menuItemDisabled {
  background-color: #f0f0f0;
}
