.root {
  position: relative;
  width: 675px;
  background: #fff;
  box-shadow: 0 4px 40px rgba(13, 28, 51, 0.16);
  border-radius: 2px;
  outline: none;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 0px 39px 43px 39px;
  text-align: center;
}

.btnClose {
  position: absolute;
  right: 40px;
  top: 40px;
  height: 14px;
  width: 14px;
  background: transparent;
  border: 0px;
  padding: 0 0;
  min-height: 12px;
}

.btnClose:hover {
  background: transparent;
  border: 0px;
}

.btnClose:active {
  background: transparent;
  border: 0px;
}

.btnClose svg {
  fill: #97a1bc;
}

.btnClose:hover svg {
  fill: #22366d;
}

.btnClose:active svg {
  opacity: 0.8;
}

.btnClose:disabled {
  border: 0px;
  background-color: transparent;
  opacity: 0.5;
}

.titleBlock {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.robotImage {
  height: 247px;
}

.title {
  font-weight: 500;
  font-size: 32px;
  color: #000a19cc;
  margin-top: 37px;
  margin-bottom: 17px;
  line-height: 25px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 87px 0px 0px 0px;
}

.btn {
  max-width: 195px;
  margin-left: 16px;
}

.textfield {
  width: 100%;
  box-sizing: border-box;
}

.textfield:not(:last-child) {
  margin-right: 21px;
}

.row {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  margin-bottom: 11px;
}

.rowAlignEnd {
  display: flex;
  justify-content: stretch;
  align-items: flex-end;
  margin-bottom: 11px;
}

.row:not(:first-child) {
  margin-top: 9px;
}

.map {
  height: 250px;
  margin-top: 18px;
}

.buttonDelete {
  height: 40px;
  max-width: 140px;
}
