.root {
  position: absolute;
  z-index: 100;
  bottom: 53px;
  right: 54px;
}

.button {
  width: 29px;
  height: 29px;
  display: block;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;

  background: #fff;
  border: 1px solid #dadde5;
  border-radius: 2px !important;
  box-shadow: none;
}

.button:not(:disabled):hover {
  background-color: #f2f2f2;
}

.icon {
  background-image: url('images/icons/overlay.svg');
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
}

.popover {
  margin-top: -100px;
  background: #fff;
  width: 200px;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.popoverHeader {
  box-sizing: border-box;
  padding: 12px 16px;
  height: 40px;
  display: flex;
  align-items: center;

  font-weight: 700;
  font-size: 14px;

  color: rgba(0, 10, 25, 0.8);
  background: #d9d9d9;
}

.popoverItem {
  box-sizing: border-box;
  padding: 12px 16px;
  height: 40px;
  display: flex;
  align-items: center;

  font-weight: 400;
  font-size: 14px;
  color: rgba(27, 43, 72, 0.6);
  cursor: pointer;
}

.popoverItem:hover {
  opacity: 0.8;
}

.popoverItemActive {
  font-weight: 500;
  color: rgba(0, 10, 25, 0.8);
}

.popoverItemDisabled {
  opacity: 0.5 !important;
  cursor: default;
}

.popoverItemDisabled:hover {
  opacity: 0.5 !important;
}

.popoverSeparator {
  width: 100%;
  height: 1px;
  background: #dadde5;
}
