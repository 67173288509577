.buttons {
  display: flex;
  justify-content: flex-end;
}

.button {
  max-width: 160px;
  margin-left: 16px;
}

.radioButtons {
  display: flex;
  margin-top: 14px;
  margin-bottom: 34px;
}

.radioButton {
  flex: 1 1 auto;
}

.subtitle {
  margin-bottom: 5px;
}

.desc {
  margin-top: 17px;
  margin-bottom: 15px;
}

.content {
  flex: 1 1 auto;
}

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.textfield {
  margin-bottom: 35px;
  width: 100%;
  box-sizing: border-box;
}

.fileSelectedField {
  width: 100%;
  box-sizing: border-box;
}

.button {
  max-width: 152px;
  height: 40px;
  /* margin-left: 16px; */
  font-size: 14px;
}

.buttonSmall {
  max-width: 40px;
  height: 40px;
  /* margin-left: 16px; */
  border: 0cap;
}

.removeIcon {
  transform: scale(2);
}

.selectFileBlock {
  margin-top: 35px;
  display: flex;
  align-items: flex-end;
}
