.root {
  position: relative;
  width: 500px;
  background: #fff;
  box-shadow: 0 4px 40px rgba(13, 28, 51, 0.16);
  border-radius: 2px;
  outline: none;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 0px 35px 32px 35px;
}

.title {
  font-weight: 500;
  font-size: 32px;
  color: #000a19cc;
  margin-top: 37px;
  margin-bottom: 17px;
  line-height: 32px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 43px 0px 0px 0px;
}

.btn {
  max-width: 160px;
  margin-left: 16px;
}

.btnRed {
  background-color: rgb(208, 64, 64);
  border: 1px solid rgb(208, 64, 64);
}

.btnRed:hover {
  background-color: rgba(208, 64, 64, 0.752);
  border: 1px solid rgba(208, 64, 64, 0.752);
}

.titleBlock {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
