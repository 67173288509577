/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.labelActive {
  margin-left: 30px;
  color: #000a19cc;
}

.labelInactive {
  margin-left: 30px;
  color: #1b2b4899;
}

.check {
  display: block;
  position: absolute;
  border: 2px solid #aaaaaa;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  top: 0px;
  left: 0px;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

.check::before {
  display: block;
  position: absolute;
  content: '';
  border-radius: 100%;
  height: 10px;
  width: 10px;
  top: 3px;
  left: 3px;
  /* margin: auto; */
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

.checkDisabled {
  border: 2px solid #d1d1d1;
}

input[type='radio']:checked ~ .check {
  border: 2px solid #000a19cc;
}

input[type='radio']:checked ~ .check::before {
  background: #000a19cc;
}

input[type='radio']:checked ~ .checkDisabled {
  border: 2px solid #474747cc;
}

input[type='radio']:checked ~ .checkDisabled::before {
  background: #474747cc;
}
