.tabContent {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-grow: 1;
}

.invisible {
  display: none;
}
