.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.button {
  max-width: 152px;
  height: 40px;
  margin-left: 16px;
  font-size: 14px;
}

.description {
  background: rgba(218, 221, 229, 1);
  flex: 1 1 0;
  color: rgba(27, 43, 72, 0.6);
  align-self: stretch;
  padding-left: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}

.descriptionStart {
  font-size: 12px;
  font-weight: 400;
  color: rgba(27, 43, 72, 0.6);
}
