.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tableRow {
  grid-template-columns: 1fr 2fr 2.5fr 3fr;
  display: grid;
  height: 70px;
  box-shadow: 0px -1px 0px 0px rgba(218, 221, 229, 1) inset;
  align-items: center;
  text-align: center;
  font-size: 16px;
  padding: 0 30px 0 20px;
}

.tableLockRow {
  grid-template-columns: 3fr 1fr;
  display: grid;
  height: 70px;
  box-shadow: 0px -1px 0px 0px rgba(218, 221, 229, 1) inset;
  align-items: center;
  text-align: center;
  font-size: 16px;
  padding: 0 50px 0 32px;
}

.chip {
  border-radius: 14px;
  height: 35px;
  display: flex;
  align-items: center;
  min-width: 101px;
  margin: 0 5px;
}

.textAlignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.online {
  background-color: #3ab084cc;
}

.centered {
  justify-content: center;
}

.bold {
  font-weight: 700;
}
