.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tableWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tableRow {
  grid-template-columns: 1fr 2fr 2.5fr 3fr;
  display: grid;
  height: 70px;
  box-shadow: 0px -1px 0px 0px rgba(218, 221, 229, 1) inset;
  align-items: center;
  text-align: center;
  font-size: 16px;
  padding: 0 30px 0 10px;
}

.row {
  min-height: 70px;
  box-shadow: 0px -1px 0px 0px rgba(218, 221, 229, 1) inset;
  display: flex;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  align-items: center;
  flex-wrap: wrap;
}

.chip {
  border-radius: 14px;
  height: 35px;
  display: flex;
  align-items: center;
  min-width: 101px;
  margin: 0 5px;
}

.unlocked {
  background-color: rgba(128, 145, 192, 0.4);
}

.locked {
  background-color: rgba(245, 247, 249, 1);
}

.icon {
  margin: 0 15px;
}

.locksTitle {
  margin-left: 33px;
  margin-right: 15px;
}

.centered {
  justify-content: center;
}

.percents {
  text-align: end;
}

.textAlignLeft {
  text-align: left;
}

.online {
  background-color: #3ab084cc;
}
