.tableWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tableHeader {
  grid-template-columns: 2.5fr 3fr 2.5fr 3fr 3fr 3fr 1fr;
  display: grid;
  min-height: 45px;
  box-shadow: 0px -1px 0px 0px rgba(218, 221, 229, 1) inset;
}

.tableHeaderItem {
  align-items: center;
  display: flex;
  padding-left: 5px;
}

.tableHeaderItemSmall {
  align-items: center;
  display: flex;
  justify-content: center;
}

.emptyWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: rgba(99, 114, 154, 1);
}

.emptyTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 16px;
}

.emptyDescription {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: center;
  max-width: 330px;
}

.box {
  padding: 0px;
  display: flex;
  min-height: 0;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.titleBlock {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.button {
  width: auto;
  min-width: 160px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  box-sizing: border-box;
}

.table {
  overflow-y: auto;
}

.main {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1 1 auto;
  min-height: 0;
  min-width: 650px;
}
