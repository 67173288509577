.root {
  background: #ffffff;
  padding: 20px 24px;
  font-size: 12px;
  color: #22366d;
  height: 100%;
  width: 100%;
  box-shadow: 0px 2px 6px 0px #8091c066;
  box-sizing: border-box;
}
